import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import './DownloadTextPage.css'; // Importa el archivo CSS
import logo from './texto-extraido-escaneado.png'; // Importa el logotipo

function DownloadTextPage() {
  const location = useLocation();
  const navigate = useNavigate(); // Hook para la navegación
  const { signedUrl } = location.state || {}; // Obtenemos la URL firmada desde el estado de la navegación

  return (
    <div className="download-container">
      <h1>Texto Extraído</h1>
      {signedUrl ? (
        <a href={signedUrl} download className="download-button">
          Haz clic para descargar tu texto extraído !!!!
        </a>
      ) : (
        <p>Cargando...</p>
      )}
      <button className="back-button" onClick={() => navigate('/')}>
        Volver al inicio
      </button>
      <img src={logo} alt="TacPDF Logo" className="logo2" /> {/* Logotipo */}
    </div>
  );
}

export default DownloadTextPage;
