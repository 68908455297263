import React, { useState } from 'react';

import './PaymentGateway.css'; // Importa el archivo CSS

function RecargarSaldo() {
  const [monto, setMonto] = useState('');

  const handleRecargar = () => {
    // Lógica para procesar la recarga de saldo aquí
    console.log('Monto a recargar:', monto);
    if (monto) {
      
      // Realizar la solicitud GET al servidor para recargar saldo
      fetch(`http://localhost:4000/api/recargar?monto=${monto}`, {
        method: 'GET',
      })
        .then((response) => response.json())
        .then((data) => {
          // Manejar la respuesta del servidor aquí
          alert(data.message); // Muestra el mensaje de éxito o error
          // Puedes realizar acciones adicionales aquí, como redirigir al usuario
        })
        .catch((error) => {
          // Manejar errores aquí
          console.error('Error al procesar la recarga de saldo:', error);
        });
    }
  };

  return (
    <div className="recargar-saldo">
      <h2>Recargar Saldo</h2>
      <form>
        <div className="form-group">
          <label>Monto a Recargar:</label>
          <input
            type="number"
            value={monto}
            onChange={(e) => setMonto(e.target.value)}
            placeholder="Ingrese el monto a recargar"
          />
        </div>
        <button type="button" onClick={handleRecargar}>
          Recargar
        </button>
      </form>
    </div>
  );
}

export default RecargarSaldo;

