import React, { useEffect, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import axios from 'axios';

function ExtractTextPage() {
  const [pdfPreview, setPdfPreview] = useState(null);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const { fileURL } = location.state || {};

  useEffect(() => {
    if (!fileURL) {
      console.log('No se encontró fileURL en el estado, redirigiendo a la página principal...');
      navigate('/');
    } else {
      console.log('fileURL encontrado en el estado:', fileURL);
      setPdfPreview(fileURL);
    }
  }, [fileURL, navigate]);

  const handleExtractText = async () => {
    setLoading(true);
    try {
      console.log('Enviando solicitud para extraer texto del archivo:', fileURL.split('/').pop());
      const response = await axios.post('https://dhwaqifkkdpkyglcdkfr6x73fa0yymta.lambda-url.us-east-1.on.aws/', {
        bucket: 'pdfextracttools',
        key: fileURL.split('/').pop()
      });
      console.log('Respuesta completa del servidor:', response);
      const { data } = response;

      if (data.signedUrl) {
        console.log('URL firmada obtenida:', data.signedUrl);
        navigate('/downloadtextpage', { state: { signedUrl: data.signedUrl } });
      } else {
        console.error('No se encontró una URL firmada en la respuesta del servidor.');
        alert('Hubo un problema al obtener la URL firmada.');
      }
    } catch (error) {
      console.error('Error al extraer el texto:', error);
      console.error('Detalles del error:', error.response ? error.response.data : 'No se proporcionaron detalles del error');
      alert('Hubo un error al extraer el texto.');
    } finally {
      setLoading(false);
    }
  };

  return (
    <div>
      {pdfPreview && (
        <div>
          <h2>Previsualización del PDF</h2>
          <object data={pdfPreview} type="application/pdf" width="100%" height="500px">
            <p>El archivo PDF no se puede mostrar en este navegador. Puedes <a href={pdfPreview}>descargarlo aquí</a>.</p>
          </object>
          <button onClick={handleExtractText} disabled={loading}>
            {loading ? 'Extrayendo texto...' : 'Extraer texto'}
          </button>
        </div>
      )}
    </div>
  );
}

export default ExtractTextPage;
































