import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import logoImage from './pdf-escaneado-a-texto-pdf-to-text-image.jpeg';

function PaymentGateway() {
  const [amount, setAmount] = useState('');
  const [paymentMethod, setPaymentMethod] = useState('efectivo');
  const navigate = useNavigate(); // Utiliza useNavigate en v6

  const handleCashReload = () => {
    // Lógica para procesar la recarga en efectivo
    // Asegúrate de validar la cantidad ingresada y registrar la transacción
  };

  const handleExit = () => {
    // Lógica adicional antes de salir si es necesario
    // Por ejemplo, limpiar el estado o realizar alguna acción antes de salir
    navigate('/salida'); // Navegar a la página de salida
  };

  return (
    <div className="payment-container">
      
      

      <div className="payment-method">
        <h3>TacPDF</h3>
        <br />
        <br />
        <br />
        <h3>!Gracias por pertenecer a nuestra vibrante comunidad¡</h3>
        <br />
        <br />
        <br />
        <h3>Ahora que te registraste, debes seleccionar tu archivo de más de 5 páginas, dar click en </h3>
        <h3> "subir archivo" y loguearte para iniciar la extracción</h3>
        <div className="method-options">
          {/* ... (opciones de método de pago) */}
        </div>
      </div>
        <br />
        <br />
        <br />
        <br />
        
      <button className="salir-button" onClick={handleExit}>
        volver al inicio
      </button>
    </div>
  );
}

export default PaymentGateway;



          
